import React from 'react'

const Steps2 = ({elem}) => {
  return (
    <div className='steps-2'>
        <div className="main-container">
            <div className="row">
                <div className="col-12">
                    <h3 className='h3_1dd7_10'>{elem.title}</h3>
                </div>
                <div className='steps-2-wrapper'>
                    {elem.elements?.map((card, index) =>
                        <div className="steps-item" key={index}>
                            <img src={card.image} alt="" />
                            <div className='steps-2-back'>
                                <h4>{card.title}</h4>
                                <div dangerouslySetInnerHTML={{ __html: card.description }}></div>
                            </div>
                        </div>

                    )}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Steps2