import React from 'react'
import OpeningSlider from '../../components/complex/OpeningSlider'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from "../../services/general/appServices";
import Advantages from '../../components/complex/Advantages';
import About from '../../components/complex/About';
import AboutUs from '../../components/complex/AboutUs';
import Advantages2 from '../../components/complex/Advantages2';
import NewsBlog from '../../components/complex/NewsBlog';
import Video from '../../components/complex/Video';
import Faq from '../../components/complex/Faq';
import Steps from '../../components/complex/Steps';
import ContactForm from '../../components/complex/ContactForm';
import EventsBlock from '../../components/complex/EventsBlock';
import Partners from '../../components/complex/Partners';
import ChessComponent from '../../components/complex/ChessComponent';
import ThreeColumn from '../../components/complex/ThreeColumn';
import Gallery from '../../components/complex/Gallery';
import TextComponent from '../../components/complex/TextComponent';
import Workers from '../../components/complex/Workers';
import { useNavigate, useParams } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import { Helmet } from 'react-helmet'
import { useEffect } from 'react';
import Steps2 from '../../components/complex/Steps2';
import ShortsVideo from '../../components/complex/ShortsVideo';
import Opening5 from '../../components/complex/Opening5';

const MainPage = () => {
    const navigate = useNavigate();
    const {lang} = useLangContext();
    const{lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2){
                navigate(`/${lang}`);
            }
        }
    }, [lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'main')

  return (
    <>
        {loading ?
            <div className="preloader">
                <div className="isLoading"></div>
            </div>
            :
        <div>
            {items &&
                <Helmet>
                    <title>{items.seo_title ? items.seo_title : items.title}</title>
                    {items.seo_description && <meta name="description" content={items.seo_description} />}
                    {items.seo_keywords && <meta name="Keywords" content={items.seo_keywords}/>}
                </Helmet>
            }
            {items &&
                items.elements?.map((elem, index) =>
                    <div key={index}>
                        {
                            elem.type === "opening-slider" ?
                                <OpeningSlider elem={elem}/>
                            :
                            elem.type === "opening-5" ?
                                <Opening5 elem={elem}/>
                            :
                            elem.type === "advantages" ?
                                <Advantages elem={elem}/>
                            :
                            elem.type === "about" ?
                                <About elem={elem}/>
                            :
                            elem.type === "about-2" ?
                                <AboutUs elem={elem}/>
                            :
                            elem.type === "advantages-2" ?
                                <Advantages2 elem={elem}/>
                            :
                            elem.type === "articles" ?
                                <NewsBlog elem={elem}/>
                            :
                            elem.type === "video-component" ?
                                <Video elem={elem}/>
                            :
                            elem.type === "faq" ?
                                <Faq elem={elem}/>
                            :
                            elem.type === "steps" ?
                                elem.is_component ?
                                    <Steps2 elem={elem}/>
                                    :
                                    <Steps elem={elem}/>
                            :
                            elem.type === "events-2" ?
                                <EventsBlock elem={elem}/>
                            :
                            elem.type === "form" ?
                                <ContactForm elem={elem}/>
                            :
                            elem.type === "partners" ?
                                <Partners elem={elem}/>
                            :
                            elem.type === "chess" ?
                                <ChessComponent elem={elem}/>
                            :
                            elem.type === "text-component-title" ?
                                <ThreeColumn elem={elem}/>
                            :
                            elem.type === "gallery" ?
                                <Gallery elem={elem}/>
                            :
                            elem.type === "text-information" ?
                                <TextComponent elem={elem}/>
                            :
                            elem.type === "workers" ?
                                <Workers elem={elem}/>
                            :
                            elem.type === "video-component-2" ?
                                <ShortsVideo elem={elem}/>
                            :
                            <></>
                        }
                    </div>    
                )
            }
        </div>
        }
    </>
  )
}

export default MainPage