import React, { useEffect, useState } from 'react'

const Opening5 = ({elem}) => {
    const [imgDesk, setImgDesk] = useState();
    const [imgMob, setImgMob] = useState();

    useEffect(() => {
        if(elem?.elements){
            setImgDesk(elem.elements.find(it => it.type === "banner_image_back")?.image);
            setImgMob(elem.elements.find(it => it.type === "banner_image")?.image);
        }
    }, [elem])

  return (
    <div className='opening-5'>
        <img src={imgDesk} alt="" className='mob-none' />
        <img src={imgMob} alt="" className='desk-none'/>
    </div>
  )
}

export default Opening5